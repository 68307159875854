import useWindowDimensions from "@/src/hooks/useWindowDimensions"
import { useCallback, useEffect, useState } from "react"

const WIDTH_BREAKPOINT = 1024

const useIsMobile = () => {
    const { width } = useWindowDimensions()

    const getMobile = useCallback(() => {
        return width < WIDTH_BREAKPOINT
    }, [width])

    const [mobile, setMobile] = useState(
        getMobile(),
    )

    useEffect(() => {
        setMobile(getMobile())
    }, [getMobile])

    return mobile
}

export default useIsMobile
