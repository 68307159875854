import { Fragment, memo } from 'react'
import LoadMoreButton from '../LoadMoreButton/LoadMoreButton'
import SideSection from '../SideSection'
import PostCard from './PostCard'
import dynamic from 'next/dynamic'

const Ad = dynamic(() => import('@/src/components/Ad'), { ssr: false })

interface PostsProps {
    posts: any
    isEnd: boolean,
    setSize: any,
    size: number,
    isLoading: boolean
}

const Posts = ({ posts, size, setSize, isEnd, isLoading }: PostsProps) => {
    return (
        <div className='flex flex-row md:grid md:grid-cols-5 xl:gap-0 h-auto'>
            <div className='md:col-span-5 xl:col-span-4 w-fit'>
                {posts?.map((post: any, index: number) => {
                    return (
                        <Fragment key={index}>
                            {(index) % 4 === 0 ?
                                <Ad id={'post-ad-desktop' + index}
                                    shouldAddNew
                                    pos='incontent1'
                                    className='flex w-full text-center relative mb-10'
                                    closeButton={true}
                                    category={undefined}
                                    postId={undefined} />
                                : null}

                            <PostCard
                                imageWrapperStyle='w-[450px]'
                                key={`${index}-post-id`} post={post} />
                        </Fragment>

                    )
                })}
                {isLoading &&
                    (
                        <div className='flex flex-col gap-4 w-[92vw] pr-12 pb-12'>
                            <div className='skeleton h-32 w-full'></div>
                            <div className='skeleton h-4 w-28'></div>
                            <div className='skeleton h-4 w-full'></div>
                            <div className='skeleton h-4 w-full'></div>
                        </div>
                    )}
            </div>
            <SideSection />
            {
                !isEnd && (
                    <div className='hidden md:col-span-6 md:flex md:justify-center'>
                        <LoadMoreButton onClick={() => setSize(size + 1)} />
                    </div>
                )
            }

        </div>
    )
}

export default memo(Posts)
