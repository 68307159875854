import { getExcerpt } from '@/components/utils/embedded-utils'
import { int } from 'aws-sdk/clients/datapipeline'
import Image from 'next/image'
import Link from 'next/link'
import { memo, useState } from 'react'
import { getDictionary, getImagePath, isHot97 } from '../../../dictionary'
import AnglesRightIconSVG from '../../AnglesRightIconSVG'
import { formatDate } from '../../utils/date-utils'
import { useRouter } from 'next/router'
import { Roboto } from 'next/font/google'
import useIsMobile from '../../../hooks/useIsMobile'

interface PostCardProps {
    post: any,
    articleStyle?: string
    infoStyle?: string
    imageWrapperStyle?: string
    setContent?: boolean
    contentLength?: int
}

const DEFAULT_CONTENT_LENGTH = 150
const DEFAULT_TITLE_LENGTH = 150
const roboto = Roboto({ subsets: ['latin'], weight: '400' })

const PostCard = ({
                      post,
                      articleStyle = `flex flex-col md:flex-row md:mb-12 md:h-64 lg:h-80  ${isHot97() ? 'bg-black' : 'bg-white'}`,
                      infoStyle = `flex flex-col px-4 py-2 text-content w-full md:px-6 md:pt-6 flex-1 ${isHot97() ? 'bg-black' : 'bg-white'}`,
                      imageWrapperStyle = '',
                      setContent = true,
                      contentLength = DEFAULT_CONTENT_LENGTH,
                  }: PostCardProps) => {
    const fallBack = getImagePath('website-logo.png')
    const imgURL = post.fields.banner?.fields?.file ? `https:${post.fields.banner.fields.file.url}` : fallBack
    const [imgSrc, setImgSrc] = useState(imgURL)
    const onError = () => setImgSrc(fallBack)
    const router = useRouter()
    const isMobile = useIsMobile()

    if (!post) {
        return (
            <></>
        )
    }

    const title = getExcerpt(post.fields.title.toString(), DEFAULT_TITLE_LENGTH)
    const content = setContent ? getExcerpt(post.fields.content.toString(), contentLength ? contentLength : DEFAULT_CONTENT_LENGTH) : null

    return (
        <article className={`${articleStyle} relative py-4 lg:py-0`}>
            <Image
                key={post.sys.id}
                src={`${imgSrc}`}
                className={`${imageWrapperStyle} cursor-pointer object-cover`}
                blurDataURL={`${imgSrc}?q=5`}
                placeholder='blur'
                alt={title}
                width={isMobile ? 746 : 700}
                height={isMobile ? 420 : 500}
                sizes='(max-width: 1200px) 80vw, (max-width: 768px) 70vw, (max-width: 500px) 50vw, 33vw'
                data-ll-status='loaded'
                onError={() => onError()}
                onClick={() => router.push(`${getDictionary('postsPath')}${post.fields.slug}`)}
                onLoadingComplete={(image) => image.classList.remove('opacity-0')}
                priority
                quality={80}
                fetchPriority={'high'}
            />
            <div className={infoStyle}>
                <h1 className='text-4xl font-[Judge] line-clamp-2'>
                    <Link prefetch={false} href={getDictionary('postsPath') + post.fields.slug}
                          className={`decoration-none text-content`}>
                        {title}
                    </Link>
                </h1>
                <p className='text-2xl text-hot-yellow font-bold font-[Oskari]'>
                    {formatDate(post.fields.date)}
                </p>
                {
                    setContent ?
                        <p className={`hidden md:block text-base overflow-hidden text-break text-ellipsis break-all text-content ${roboto.className}`}>
                            {content}...
                            <AnglesRightIconSVG />
                        </p>
                        : null
                }
            </div>
        </article>
    )
}

export default memo(PostCard)
