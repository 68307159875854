import { client } from '@/contentful/client'

export const getAllPagesPaths = async () => {

    const result = []
    const params = {
        content_type: 'pagesV2',
        limit: 1000
    }
    const pages = await client.getEntries(params)
    pages.items.map(page => {
        result.push({
            params: {
                url: [page.fields.url],
                category: [page.fields.category],
            },
        })
    })

    return result
}

export const getPagesByCategory = async (limit, category) => {
    const params = {
        content_type: 'pagesV2',
        'fields.category': category,
    }

    if (limit != null) {
        params.limit = limit
    }

    const pages = await client.getEntries(params)
    return pages.items
}

export const getPage = async (url, category) => {
    const postResponse = await client.getEntries({
        content_type: 'pagesV2',
        limit: 1,
        'fields.url': url,
        'fields.category': category,
    })
    return postResponse.items[0]
}

export const getSinglePage = async (url) => {
    const postResponse = await client.getEntries({
        content_type: 'pagesV2',
        limit: 1,
        'fields.url': url,
    })
    return postResponse.items[0]
}
