import React from 'react'
import SectionHeader from './SectionHeader'

interface SectionProps {
    title: string,
    sectionStyle?: string,
    children: React.ReactNode,
    showHeader?: boolean,
    noPadding?: boolean
}

export default function Section({ title, sectionStyle, children, showHeader = false, noPadding } : SectionProps) {
    return (
        <section className={sectionStyle ? sectionStyle : `bg-body ${noPadding ? 'px-0 pb-0' : 'px-12 pb-12'}`}>
            <SectionHeader title={title} showHeader={showHeader} />
            <div>
                {children}
            </div>
        </section>
    )
}
