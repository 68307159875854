import { isHot97 } from '@/src/dictionary'
import { memo } from 'react'

interface SectionHeaderProps {
    title: string,
    showHeader?: boolean
}

const SectionHeader = ({ title, showHeader = false } : SectionHeaderProps) => {
    return (
        <div className={`items-center ${showHeader ? 'flex' : 'hidden'} md:flex py-8`}>
            <h1 className={`text-content flex flex-1 items-center text-5xl font-[Judge]`}>
                <span className='pr-1'>{title}</span>
                <div className={`border-b-2 flex-1 border-content`}></div> 
            </h1>
        </div>
    )
    
}

export default memo(SectionHeader)