import { isHot97 } from "@/src/dictionary";

export default function AnglesRightIconSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="inline ml-2" width="11" height="11" color={`${isHot97() ? '#FDB827' : '#BA1B1B'}`} viewBox="0 0 11 11" fill={`${isHot97() ? '#FDB827' : '#BA1B1B'}`}>
        <g clipPath="url(#clip0_403_757)">
        <path d="M10.1107 5.98521C10.3792 5.71665 10.3792 5.28052 10.1107 5.01196L6.67319 1.57446C6.40464 1.30591 5.96851 1.30591 5.69995 1.57446C5.4314 1.84302 5.4314 2.27915 5.69995 2.5477L8.65191 5.49966L5.7021 8.45161C5.43354 8.72017 5.43354 9.1563 5.7021 9.42485C5.97065 9.69341 6.40679 9.69341 6.67534 9.42485L10.1128 5.98735L10.1107 5.98521ZM2.54819 9.4227L5.98569 5.98521C6.25425 5.71665 6.25425 5.28052 5.98569 5.01196L2.54819 1.57446C2.27964 1.30591 1.84351 1.30591 1.57495 1.57446C1.3064 1.84302 1.3064 2.27915 1.57495 2.5477L4.5269 5.49966L1.5771 8.45161C1.30854 8.72017 1.30854 9.1563 1.5771 9.42485C1.84565 9.69341 2.28179 9.69341 2.55034 9.42485L2.54819 9.4227Z" fill={`${isHot97() ? '#FDB827' : '#BA1B1B'}`}/>
        </g>
        <defs>
            <clipPath id="clip0_403_757">
            <rect width="11" height="11" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
}


