import { getExcerpt } from '@/components/utils/embedded-utils'
import { getDictionary, getImagePath } from '@/src/dictionary'

export function setMetaTags(host, props, currentURL) {
    if (props.post) {
        return setPostMetaTags(props.post, currentURL, host)
    } else if (props.page) {
        return setPageMetaTags(props.page, currentURL, host)
    } else if (props.talent) {
        return setTalentMetaTags(props.talent, currentURL, host)
    } else if (props.tag) {
        return setTagMetaTags(props.tag, currentURL, host)
    } else {
        return setSimpleMetaTags(host, currentURL)
    }
}

function safeImageUrl(imgURL, host) {
    if (imgURL) {
        return `https:${imgURL}?w=1200`
    } else {
        return `https://${host}${getImagePath('website-logo.png')}`
    }
}

function setPostMetaTags(post, currentURL, host) {
    const title = post.title
    const description = getExcerpt(post.content, 150)
    const imgURL = safeImageUrl(post.banner?.fields?.file?.url, host)
    return renderOGTags(title, description, imgURL, currentURL, host)
}

function setPageMetaTags(page, currentURL, host) {
    const title = page.fields.title
    const description = getExcerpt(page.fields.content, 150)
    const imgURL = safeImageUrl(page.fields?.banner?.fields?.file?.url, host)
    return renderOGTags(title, description, imgURL, currentURL, host)
}

function setTalentMetaTags(talent, currentURL, host) {
    const title = talent?.fields?.name
    const description = getExcerpt(talent?.fields?.bio.content[0]?.content[0]?.value, 150)
    const imgURL = safeImageUrl(talent?.fields?.profilePic.fields.file.url, host)
    return renderOGTags(title, description, imgURL, currentURL, host)
}

function setTagMetaTags(tag, currentURL, host) {
    const title = tag.name
    const description = `Posts with tag ${tag.name}`
    const imgURL = safeImageUrl(null, host)
    return renderOGTags(title, description, imgURL, currentURL, host)
}

function setSimpleMetaTags(host, currentURL) {
    const title = getDictionary('indexTitle')
    const description = getDictionary('indexDescription')
    const imgURL = safeImageUrl(null, host)
    return renderOGTags(title, description, imgURL, currentURL, host)
}

function renderOGTags(title, description, imgURL, currentURL, host) {
    const fullHost = host.startsWith('www') ? host : `www.${host}`
    const cleanCurrentUrl = currentURL.split('?')[0]
    const fullUrl = `https://${fullHost}${cleanCurrentUrl}`
    return (
        <>
            {/* HTML Meta Tags */}
            <title>{title}</title>
            <link
                rel='icon'
                href={getImagePath('website-logo.png')}
                type='image/svg'
                sizes='any'
            />

            <link
                rel='canonical'
                href={fullUrl}
            />

            <meta name='description' content={description} />

            {/* Facebook Meta Tags */}
            <meta property='og:url' content={fullUrl} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={imgURL} />
            <meta property='og:image:secure_url' content={imgURL} />
            <meta property='og:image:width' content='1200' />
            <meta property='og:image:height' content='630' />

            {/* Twitter Meta Tags */}
            <meta property='twitter:url' content={fullUrl} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <meta name='twitter:image' content={imgURL} />
        </>
    )
}
