import { getPagesByCategory } from "@/src/contentful/getPages"
import { getDictionary } from "@/src/dictionary"
import Link from "next/link"
import { memo, useEffect, useState } from "react"
import { getExcerpt } from "../utils/embedded-utils"
import AnglesRightIconSVG from "../AnglesRightIconSVG"
const SideSection = () => {

    const [events, setEvents] = useState<any[]>([])

    useEffect(() => {
        const fetchData = async () => {
           const data = await getPagesByCategory(5, 'events')
           setEvents(data)
        }
        
        fetchData()
    }, [])

    return (
        <div className="xl:block xl:col-span-1 hidden bg-body md:ml-4">
            <div className='bg-hot-yellow w-full p-6'>
            <h2 className="text-body text-4xl font-[Judge]">
                {getDictionary('websiteName')} Events
            </h2>
            </div>

            <div className="flex flex-col bg-black px-6 py-2">
                {
                    events?.map((event: any) => (
                        <div key={event.fields.url} className="py-2 cursor-pointer">
                            <Link prefetch={false} href={`/events/${event.fields.url}`}>
                                <h1 className="text-hot-yellow truncate font-[Judge] text-4xl" >{event.fields.title}</h1>
                                <p className="text-white text-md font-[Oskari]">
                                    { getExcerpt(event.fields.content, 100) }...
                                     <AnglesRightIconSVG />
                                </p>
                            </Link>
                        </div>
                    ))
                }
                
            </div>
        </div>                
    )
}

export default memo(SideSection)